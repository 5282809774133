import { Button, LoadingOverlay, Tabs, useMantineTheme } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconSettings } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import PageLayout from '../../components/PageLayout';
import {
  ApprovalStatus,
  IntegrationActivationStatus,
  TestCaseStatus,
  capabilitiesDisabledStatuses,
} from '../../models';
import { testFrameworkActionCreator } from '../../redux/actions';
import { RootState } from '../../redux/common';
import { AuthenticationState, IntegrationState, TestFrameworkState } from '../../redux/reducers';
import { testFrameworkService } from '../../service';
import Overview from './Overview';
import TestScenarios from './TestScenarios';
import { uatStyles } from './styles';

const UAT: FC = () => {
  const theme = useMantineTheme();
  const dispatch = useDispatch();
  const { projectId, tabValue } = useParams();
  const navigate = useNavigate();
  if (!projectId) {
    throw new Error('Active project is required but was not found.');
  }

  const {
    selectedFlow,
    selectedSubFlow,
    selectedTestSuite,
    testSuiteDefinitions,
    selectedTestCase,
    testCaseExecutionData,
    testSuiteInstanceData,
  } = useSelector<RootState, TestFrameworkState>((state: RootState) => {
    return state.testFramework;
  });

  const { userData } = useSelector<RootState, AuthenticationState>((state: RootState) => {
    return state.authentication;
  });

  const { activatedIntegrations } = useSelector<RootState, IntegrationState>((state: RootState) => {
    return state.integration;
  });

  useEffect(() => {
    dispatch(testFrameworkActionCreator.toggleRefetchEnable(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(testFrameworkActionCreator.changeTestSubmittedDetails(null));
    // eslint-disable-next-line
  }, [selectedTestSuite]);

  useEffect(() => {
    refetchInstanceData();
    if (testCaseExecutionData?.overallStatus === TestCaseStatus.SUCCESS) {
      refetchInstanceData();
    }

    // eslint-disable-next-line
  }, [selectedTestSuite, selectedTestCase]);

  useEffect(() => {
    if (
      testCaseExecutionData?.overallStatus === TestCaseStatus.SUCCESS ||
      testCaseExecutionData?.overallStatus === TestCaseStatus.TO_BE_REVIEWED
    ) {
      refetchInstanceData();
    }
    // eslint-disable-next-line
  }, [testCaseExecutionData]);

  const {
    isLoading: isLoadingSuiteData,
    refetch: refetchInstanceData,
    isRefetching,
  } = useQuery({
    queryKey: ['get-selected-latest-instance-data', selectedTestSuite],
    queryFn: () => testFrameworkService.getLatestInstanceOfSuite(selectedTestSuite, projectId),
    onSuccess: (data) => {
      if (data?.instanceId !== undefined) {
        dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(data));
        navigate(`/projects/${projectId}/uat/${selectedTestSuite}/test-scenarios`);
      } else {
        dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(null));
      }
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
  });

  const { isRefetching: isRefetchingSubmittedData, refetch } = useQuery({
    queryKey: [
      'get-submitted-data',
      userData.organizationId,
      testSuiteInstanceData?.integrationType,
    ],
    queryFn: () =>
      testFrameworkService.getSubmitedDataByOrganizationIdandIntegrationType(
        userData.organizationId,
        testSuiteInstanceData?.integrationType ?? '',
        projectId,
      ),
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSubmittedDetails(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        autoClose: 2000,
        color: 'red',
      });
    },
    enabled: testSuiteInstanceData?.integrationType ? true : false,
  });

  const submitForApproval = useMutation({
    mutationFn: (instanceId: string) => {
      return testFrameworkService.submitForApproval(instanceId);
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestSuiteIntanceData(data));
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(null));
      pollForUpdates.mutate();
      refetch();
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const pollForUpdates = useMutation({
    mutationFn: () => {
      return testFrameworkService.pollForUpdates(getTestCaseInstanceId() ?? '');
    },
    onSuccess: (data) => {
      dispatch(testFrameworkActionCreator.changeTestCaseExecutionData(data));
    },
    onError: (error: any) => {
      notifications.show({
        title: error.name ?? 'Something went wrong',
        message: error.message ?? 'Something went wrong',
        color: 'red',
      });
    },
  });

  const getTestCaseInstanceId = useCallback(() => {
    return testSuiteInstanceData?.testCaseInstances?.find(
      (instance) =>
        instance.flowId === selectedFlow &&
        instance.subFlowId === selectedSubFlow &&
        instance.testCaseId === selectedTestCase,
    )?.instanceId;
  }, [testSuiteInstanceData, selectedFlow, selectedSubFlow, selectedTestCase]);

  const getselectedDefinition = useCallback(() => {
    return testSuiteDefinitions.find(({ id }) => id === selectedTestSuite);
  }, [selectedTestSuite, testSuiteDefinitions]);

  const isDisabled = () => {
    if (
      testSuiteInstanceData?.testCaseInstances?.some(
        ({ approvalStatus }) => approvalStatus === ApprovalStatus.NOT_SUBMITTED_FOR_APPROVAL,
      ) &&
      testSuiteInstanceData?.testCaseInstances
        .filter((instance) => {
          const testCaseDef = testSuiteDefinitions
            ?.find((suite) => suite.id === selectedTestSuite)
            ?.flows.find((flow) => flow.id === instance.flowId)
            ?.subFlows.find((subflow) => subflow.id === instance.subFlowId)
            ?.testCases.find((testCase) => testCase.id === instance.testCaseId);
          if (testCaseDef?.enabled) return instance;
        })
        .every((testCaseInstances) => {
          return (
            testCaseInstances.status === TestCaseStatus.SUCCESS ||
            testCaseInstances.status === TestCaseStatus.TO_BE_REVIEWED ||
            testCaseInstances.status === TestCaseStatus.SKIPPED
          );
        }) &&
      !testSuiteInstanceData?.testCaseInstances.every(
        ({ status, instanceId }) => status === null && instanceId === null,
      ) &&
      !testSuiteInstanceData?.testCaseInstances.some(
        ({ approvalStatus }) => approvalStatus === ApprovalStatus.REJECTED,
      )
    ) {
      return false;
    }
    return true;
  };

  const activeData = useMemo(
    () =>
      activatedIntegrations.find((activeData) => {
        let status = false;
        try {
          status = activeData.integration.id === testSuiteInstanceData?.integrationType;
        } catch (e) {
          console.error(e, activeData);
        }
        return status;
      }),
    [activatedIntegrations, testSuiteInstanceData?.integrationType],
  );

  const isTestCasesRejectedByAdmin = (statuses: IntegrationActivationStatus[]): boolean => {
    return (
      statuses[statuses.length - 1] === IntegrationActivationStatus.TEST_CASES_REJECTED_BY_ADMIN
    );
  };

  const isCapabilitiesDisabled = (): boolean | undefined => {
    if (activeData && activeData.status && activeData.status.length > 0) {
      if (isTestCasesRejectedByAdmin(activeData.status)) {
        return false;
      } else {
        const filterActiveData = activeData.status
          .filter((status) => status !== IntegrationActivationStatus.API_KEYS_CREATED)
          .filter((status) => status !== IntegrationActivationStatus.CAPABILITIES_SUBMITTED);

        return (
          capabilitiesDisabledStatuses.includes(filterActiveData[filterActiveData.length - 1]) ||
          activeData.status.includes(IntegrationActivationStatus.TEST_CASES_SUBMITTED_FOR_APPROVAL)
        );
      }
    }
    return true;
  };

  const configureIntegration = useCallback(() => {
    navigate(
      `/manage-projects/capabilities/${testSuiteDefinitions
        ?.find(({ id }) => id === selectedTestSuite)
        ?.integration?.toLowerCase()}/${projectId}`,
    );
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <>
      {selectedTestSuite ? (
        <PageLayout
          id={`order-page-${selectedTestSuite}`}
          headerSection={
            <PageHeader
              id={'order-page'}
              title={`${getselectedDefinition()?.name}`}
              backText="Back"
              onClickBack={() => navigate(`/projects/${projectId}/dashboard`)}
            />
          }
          isTabs={true}
          sx={uatStyles(theme)}
        >
          <LoadingOverlay
            visible={
              isLoadingSuiteData ||
              isRefetchingSubmittedData ||
              submitForApproval.isLoading ||
              isRefetching
            }
          />
          <Tabs
            color="teal"
            defaultValue="overview"
            value={tabValue}
            onTabChange={(value) =>
              navigate(`/projects/${projectId}/uat/${selectedTestSuite}/${value}`)
            }
          >
            <Tabs.List>
              <Tabs.Tab value="overview" data-cy="overview-tab">
                Overview
              </Tabs.Tab>
              <Tabs.Tab value="test-scenarios" data-cy="test-scenarios-tab">
                Test Scenarios
              </Tabs.Tab>
              <Button
                className="configuration-button"
                variant="subtle"
                leftIcon={<IconSettings size={16} />}
                onClick={configureIntegration}
                disabled={isCapabilitiesDisabled()}
              >
                Configuration
              </Button>
              <Button
                className="production-request-button"
                disabled={isDisabled()}
                onClick={() => {
                  testSuiteInstanceData?.instanceId &&
                    submitForApproval.mutate(testSuiteInstanceData?.instanceId);
                }}
              >
                Request to review UAT
              </Button>
            </Tabs.List>

            <Tabs.Panel value="overview">
              <Overview projectId={projectId} />
            </Tabs.Panel>
            <Tabs.Panel value="test-scenarios">
              <TestScenarios projectId={projectId} />
            </Tabs.Panel>
            <Tabs.Panel value="production">Production</Tabs.Panel>
          </Tabs>
        </PageLayout>
      ) : null}
    </>
  );
};

export default UAT;
